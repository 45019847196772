import { useContext, useEffect, useState } from "react";
import bg from "../../images/applybg.webp";
import image from "../../images/enrollapply.webp";
import { MainLang } from "../languagesfiles/langContext";
export default function Apply() {
  const { translations, lang } = useContext(MainLang);

  const [active, setActive] = useState("");

  const [data, setData] = useState([
    {
      id: 1,
      text: translations.BachelorsDegree,
    },
    {
      id: 2,
      text: translations.MastersDegree,
    },
    {
      id: 3,
      text: translations.Doctorate,
    },
  ]);

  useEffect(() => {
    setData([
      {
        id: 1,
        text: translations.BachelorsDegree,
      },
      {
        id: 2,
        text: translations.MastersDegree,
      },
      {
        id: 3,
        text: translations.Doctorate,
      },
    ]);
  }, [translations]);

  const openGmailProfile = () => {
    const url = `https://mail.google.com/`;
    window.open(url, "_blank");
  };
  return (
    <div className="w-full flex flex-col items-center relative">
      <img
        src={bg}
        alt=""
        className="w-full h-full object-cover absolute top-0 left-0 z-[-1]"
      />
      <div
        className={`max-w-[1519px] w-[600px] max-sm:w-[90%] flex flex-col my-[150px] bg-white rounded-[15px] p-[40px] gap-y-[20px] max-smaller:px-[20px] max-smaller:my-[100px] ${
          lang === "Arabic" && "items-end"
        }`}
      >
        <img src={image} alt="" className="w-full object-cover" />
        <h1 className="text-[30px] font-semibold">{translations.ApplyForm}</h1>
        <div
          className={`w-full pt-[30px]  flex border-t-[1px] gap-[2%] max-smaller:flex-col max-smaller:gap-y-[20px] ${
            lang === "Arabic" && "flex-row-reverse"
          }`}
        >
          <input
            type="text"
            placeholder={translations.Name}
            className={`w-[48%] max-[500px]:w-full outline-none border-[1px] h-[40px] px-[10px] rounded-[8px] ${
              lang === "Arabic" && "text-end"
            }`}
          />
          <input
            type="text"
            placeholder={translations.lastname}
            className={`w-[48%] max-[500px]:w-full outline-none border-[1px] h-[40px] px-[10px] rounded-[8px] ${
              lang === "Arabic" && "text-end"
            }`}
          />
        </div>
        <input
          type="text"
          placeholder={translations.Email}
          className={`w-full outline-none border-[1px] h-[40px] px-[10px] rounded-[8px]  ${
            lang === "Arabic" && "text-end"
          }`}
        />
        <input
          type="text"
          placeholder={translations.phonemobilewhatsapp}
          className={`w-full outline-none border-[1px] h-[40px] px-[10px] rounded-[8px]  ${
            lang === "Arabic" && "text-end"
          }`}
        />
        <input
          type="text"
          placeholder={translations.preferduni}
          className={`w-full outline-none border-[1px] h-[40px] px-[10px] rounded-[8px] ${
            lang === "Arabic" && "text-end"
          }`}
        />
        <input
          type="text"
          placeholder={translations.curswant}
          className={`w-full outline-none border-[1px] h-[40px] px-[10px] rounded-[8px] ${
            lang === "Arabic" && "text-end"
          }`}
        />

        <div className={`flex flex-col gap-y-[10px] mt-[10px] ${lang === "Arabic" && "items-end"}`}>
          <p className="font-semibold">{translations.uploadpassport}</p>
          <input type="file" className="block w-full text-sm text-gray-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-md file:border-0
      file:text-sm file:font-semibold
      file:bg-[#fd4a36] file:text-white
      hover:file:bg-[#fd7667]"/>
        </div>
        <div className={`flex flex-col gap-y-[10px] mt-[10px] ${lang === "Arabic" && "items-end"}`}>
          <p className="font-semibold">{translations.uploadeducation}</p>
          <input type="file" className="block w-full text-sm text-gray-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-md file:border-0
      file:text-sm file:font-semibold
      file:bg-[#fd4a36] file:text-white
      hover:file:bg-[#fd7667]"/>
        </div>
        <div className={`flex flex-col gap-y-[10px] mt-[10px] ${lang === "Arabic" && "items-end"}`}>
          <p className="font-semibold">{translations.Whatareyouapplyfor}</p>
          {data.map((item) => (
            <div
              key={item.id}
              className={`flex items-center gap-[5px] cursor-pointer ${lang === "Arabic" && "flex-row-reverse"}`}
              onClick={() => {
                setActive(item.id);
              }}
            >
              <div
                className={`w-[30px] h-[30px] rounded-full border-[1px]  ${
                  active === item.id ? "bg-[#fd4a36]" : ""
                }`}
              ></div>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
        <div className={`w-full ${lang === "Arabic" && "flex flex-col items-end"}`}>
          <p className="font-semibold mt-[10px]">{translations.comment}</p>
          <textarea className="outline-none border-[1px] rounded-[10px] p-[10px] focus:border-[#fd4a36] duration-200 w-full h-[200px]" />
        </div>
        <div className="flex justify-start">
          <p onClick={openGmailProfile} className="px-[15px] h-[40px] rounded-[10px] flex items-center text-white bg-[#fd4a36] hover:scale-95 duration-200 cursor-pointer">
            {translations.submitform}
          </p>
        </div>
      </div>
    </div>
  );
}
